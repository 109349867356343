import React from 'react';
import { motion } from 'framer-motion';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const cardVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};

const AboutMe = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Box mt={5} mb={5} width="85%" height="75vh" display="flex" flexDirection="column" justifyContent="center">
        {/* <Typography variant="h3" align="center">About Me</Typography> */}
        <Box style={{ overflowY: 'auto', height: 'calc(75vh - 36px)' }}>
          <Grid container spacing={3} justify="center">
            {sections.map((section, i) => (
              <Grid item xs={12} sm={6} md={4} key={i}>
                <motion.div
                  variants={cardVariants}
                  initial="hidden"
                  animate="visible"
                  transition={{ delay: i * 0.2 }}
                >
                  <Card>
                    <CardContent style={{ height: '200px', overflow: 'auto' }}>
                      <Typography variant="h5">{section.title}</Typography>
                      <Typography variant="body1">{section.content}</Typography>
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

const sections = [
  {
    title: "Birth Details",
    content: "I was born in Kolkata, India in 2001. A bustling city rich with culture and history...",
  },
  {
    title: "Early Education",
    content: "I completed my early education at Sri Sri Academy, where I developed a love for computer science...",
  },
  {
    title: "Interests",
    content: "I am deeply interested in coding, especially with a focus on Large Language Models (LLMs) and generative neural nets. Additionally, I love to explore new technologies, watch movies, and maintain an active Lettrboxd account where I rate them...",
  },
  {
    title: "Hobbies",
    content: "During my free time, I love to go hiking and immerse myself in the great outdoors. To relax, I often indulge in gaming during my off time...",
  },
  {
    title: "Sports",
    content: "I am quite active in sports, having represented my country, India, in karate. Additionally, I was my state's u-17 cricket captain. I also enjoy playing squash and football...",
  },
];


export default AboutMe;
