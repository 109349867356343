import React from 'react';
import styled from 'styled-components';

const StyledCircle = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;  // Add this
  background: linear-gradient(45deg, #FFFFFF 30%, #c5f0f0 90%);

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%; 
  background: url(${props => props.img}) no-repeat center;
  background-size: cover;
`;

const CircleComponent = ({ hoveredItem }) => {
  const img = hoveredItem ? hoveredItem.img : '/Assets/Home.png';

  return (
    <StyledCircle>
      <Image img={img} />
    </StyledCircle>
  );
};

export default CircleComponent;
