import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, CardContent, Box, Grid } from '@material-ui/core';
import { motion } from 'framer-motion';
import ProjectModal from '../ProjectModal/ProjectModal';
import SwipeableViews from 'react-swipeable-views';
// import { autoPlay } from 'react-swipeable-views-utils';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  card: {
    height: '200px',  // Set a fixed height for the card
    display: 'flex',  // Use a flex container
    flexDirection: 'column',  // Stack items vertically
    margin: theme.spacing(2),
    background: 'linear-gradient(45deg, #f0c5f0 30%, #f0f0c5 90%)',
    cursor: 'pointer',  // Add cursor on hover
    borderRadius: '7px',
  },
  content: {
    overflowY: 'auto',  // Enable vertical scrolling
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
  paginationContainer: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
  },
}));

const projectsData = [
  {
    title: 'Distributed Query Execution System with Ray',
    technology: 'Python, Ray',
    description: 'This project provides a platform to execute complex database queries on distributed data using the Ray framework.',
    detailed_description: 'This project provides a platform to execute complex database queries on distributed data using the Ray framework. Ray is a high-performance distributed computing library that allows you to write scalable and distributed programs with ease. This project supports various operations such as Scanning, Filtering, Joining, Grouping, and more. It also allows execution in two modes - pull-based and push-based evaluation - giving users the flexibility to choose the best mode for their use-case.',
    githubLink: 'https://github.com/abhi6689-develop/DistributedQueryProcessing.git',
  },
  {
    title: 'TerraSight: Remote Sensing Data Analysis',
    technology: 'Python, Ray, Docker',
    description: 'TerraSight is a scalable solution designed for processing and analyzing remote sensing data. This system leverages cloud-based technologies and aims to streamline the process of downloading, converting, and analyzing TIF files sourced from NASA repositories.',
    detailed_description: 'TerraSight is a scalable solution designed for processing and analyzing remote sensing data. This system leverages cloud-based technologies and aims to streamline the process of downloading, converting, and analyzing TIF files sourced from NASA repositories. These TIF files contain vital spatial information obtained through remote sensing technologies and are crucial for various environmental and geographical studies.',
    githubLink: 'https://github.com/abhi6689-develop/TerraSight.git',
  },
  {
    title: 'Fake News Detection using Deep Learning',
    technology: 'Python, Tensorflow, Keras, NLP',
    description: "I've harnessed Python and NLP techniques, such as TF/IDF, lemmatization, and stop word removal, to tackle the challenge of fake news detection. By integrating the Naive Bayes classifier with bagging and boosting strategies, I've developed an approach aimed at sifting through digital content to differentiate genuine information from misinformation.",
    detailed_description: "I've employed Python to spearhead an initiative against fake news. By leveraging NLP methodologies, I've incorporated TF/IDF for weighting word significance, performed stop word removal to declutter the textual data, and used lemmatization to distill words to their essential forms. Furthermore, I've integrated the probabilistic insights of the Naive Bayes model, bolstered by the robustness of both bagging and boosting techniques. This combination has facilitated a more nuanced understanding of news content, aiming to differentiate authentic articles from potentially misleading narratives."
  },
  {
    title: 'Covid Tracker',
    technology: 'Swift, firebase',
    description: "COVID Tracker - is an iOS app that provides daily updates on COVID-19 cases for each Indian state. Built with Swift, it utilizes public APIs for real-time data, with Firebase handling backend operations. A must-have for residents and travelers in India, it offers a concise view of the pandemic's regional impact.",
    detailed_description: "India COVID Tracker is a dedicated iOS application designed to keep its users abreast with the daily updated COVID-19 case numbers for each state in India. The app seamlessly integrates with public APIs to fetch real-time statistics and presents them in an intuitive and user-friendly interface built with Swift. The backend infrastructure is powered by Firebase, ensuring efficient data handling and smooth updates. With this app, users can stay informed about the pandemic's status in their state and make informed decisions about their safety and well-being. The timely updates and robust architecture make it an essential tool for every resident and traveler in India during these challenging times.",
    githubLink: 'https://github.com/abhi6689-develop/CovidIndia-Tracker.git'
  },
  {
    title: 'Alert! - Your Personal Safety Companion for iOS',
    technology: 'Swift, python, firebase',
    description: '"Alert!" is a personal safety app that bridges immediacy with peace of mind. At a single tap, it dispatches your real-time location to five pre-saved contacts, harnessing the accuracy of Google Location Services. Powered by Firebase, it ensures swift notifications while maintaining data privacy. A beacon in times of need, "Alert!" exemplifies proactive safety in the digital age.',
    detailed_description: "Alert! is more than just an app—it's a lifeline in times of uncertainty. Designed with a deep emphasis on personal safety, this iOS application ensures that, with one swift tap, your current location is accurately dispatched to five trusted contacts. By seamlessly integrating Google Location Services, it guarantees pinpoint location accuracy, ensuring that your loved ones are never in the dark about your whereabouts. And with Firebase at its core, Alert! promises both rapid data transmission and the pinnacle of data security. It's not just about alerting; it's about connecting, safeguarding, and instilling peace of mind.",
    githubLink: 'https://github.com/abhi6689-develop/Alert-.git'
  },
  {
    title: 'AutoCast Competition',
    technology: 'Python, Tensorflow, Transformers',
    description: "The Autocast project comprises Python scripts designed for question dataset processing and analysis. data_generation.py synthesizes new datasets, while model.py trains BERT-based models for specific question types. preprocess.py readies datasets for training, and submission.py predicts answers using pretrained models, saving results in a serialized format. A detailed guide is provided in README.md for users' ease of use.",
    detailed_description: "The `Autocast` project consists of a set of Python scripts designed to preprocess and analyze question datasets, train models on these datasets, and generate predictions for a test set. The main scripts include `data_generation.py`, which synthesizes new datasets based on input patterns; `model.py`, responsible for defining and training BERT-based models tailored for specific question types; `preprocess.py`, which converts datasets into suitable formats for training and evaluation; and `submission.py`, that uses pretrained BERT models to predict answers for a given test set, subsequently saving these predictions in a serialized format. All functionalities are well-documented in the `README.md` file to aid users in effectively utilizing the codebase.",
    githubLink: 'https://github.com/abhi6689-develop/AutoCastAbhishek.git'
  },

  // Add more projects here
];

const Projects = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);  // control the visibility of the modal
  const [selectedProject, setSelectedProject] = useState(null);  // store the selected project
  const [activeStep, setActiveStep] = useState(0);

  // const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

  const maxSteps = Math.ceil(projectsData.length / 6);

  const handleChangeStep = (step) => {
    setActiveStep(step);
  };

  const handleClickOpen = (project) => {
    setSelectedProject(project);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h4" className={classes.heading}>
        Things I've Built
      </Typography>
      <SwipeableViews index={activeStep} onChangeIndex={handleChangeStep}>
        {Array.from({ length: maxSteps }, (_, i) =>
          <Box key={i}>
            <Grid container spacing={2}>
              {projectsData.slice(i * 6, (i + 1) * 6).map((project, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <motion.div whileHover={{ scale: 1.05 }} onClick={() => handleClickOpen(project)}>
                    <Card className={classes.card}>
                      <CardContent className={classes.content}>
                        <Typography variant="h5">
                          {project.title}
                        </Typography>
                        <Typography variant="subtitle1">
                          {project.technology}
                        </Typography>
                        <Typography variant="body2">
                          {project.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </SwipeableViews>
      <div className={classes.paginationContainer}>
        <Pagination count={maxSteps} page={activeStep + 1} onChange={(_, page) => setActiveStep(page - 1)} />
      </div>
      <ProjectModal project={selectedProject} open={open} handleClose={handleClose} />
    </Box>
  );
};

export default Projects;
