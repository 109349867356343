import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Drawer, IconButton, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from "@material-ui/core/styles";
import { NavLink, useLocation } from 'react-router-dom';
import "./Navbar.css"
import NavbarContext from '../../Contexts/NavbarContext';
import { SpeedDials } from '../speedDial/SpeedDial';

const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  color: ${({ theme }) => theme === 'dark' ? 'white' : 'black'};
`;

const Menu = styled.div`
  display: flex;
`;

const MenuItem = styled.div`
  padding: 20px;
  font-size: 1.2em;
  cursor: pointer;
  &:hover {
    color: #00bfbf;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;

  &.active {
    color: #00bfbf;
  }
`;


const useStyles = makeStyles((theme) => ({
    drawerStyle: {
        width: "40px",
        height: "40px",
        position: "absolute",
        boxShadow:
            "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
        borderRadius: "50%",
        marginTop: '0px',
    },
    NavPage: {
        display: "flex",
    },
    menuButton: {
        backgroundColor: '#f0f0c5', // Change this to the color you want
        '&:hover': {
            backgroundColor: '#f0c5f0', // Change this to the color you want when hovered
        },
    }
}));

const menuItems = [
    { name: 'Home', img: '/Assets/Home.png', link: "/" },
    { name: 'About', img: '/Assets/About.png', link: "/about" },
    { name: 'Education', img: '/Assets/Education.png', link: "/education" },
    { name: 'Experience', img: '/Assets/Experience.png', link: "/experience" },
    { name: 'Skills', img: '/Assets/Skills.png', link: "/skills" },
];


// Other parts of your code remain the same...

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00bfbf; // Customize this color as needed
  padding: 10px;
  width: 100%; // This ensures the bar covers the entire width of the screen
  height: 60px; // Customize this height as needed
`;

const Logo = styled.h1`
    margin: 0 auto;
    text-align: center;
    color: white; // Set text color to white
    text-transform: capitalize; // Capitalize the first letter of each word
    fontFamily: 'Roboto Mono, monospace',
    fontWeight: 300,
    `;

// Other parts of your code remain the same...

const NavigationBar = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const [mobileOpen, setMobileOpen] = useState(false);
    const { setHoveredItem } = useContext(NavbarContext);
    const location = useLocation();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <List>
                {['Home', 'About', 'Education', 'Experience', 'Skills'].map((text, index) => (
                    <ListItem button key={text} component={NavLink} to={`/${text.toLowerCase()}`}>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
        </div>
    );
    const classes = useStyles();

    return (
        <Navigation className={`${classes.NavPage}`}>
            {isMobile ?
                <>
                    <TopBar>
                        <div /> {/* This empty div is needed to keep the logo centered and menu button on the right */}
                        <Typography variant="h6" className={classes.pageTitle}>
                            {/* Get the path from the location and remove the leading slash, then display it as the title */}
                            <Logo>{location.pathname.slice(1) || 'home'}</Logo>
                        </Typography>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerToggle}
                            className={`${classes.drawerStyle} ${classes.menuButton}`}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div>
                            <SpeedDials />
                        </div>
                    </TopBar>
                    <Drawer
                        anchor={'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                    >
                        {drawer}
                    </Drawer>
                </>
                :
                <Menu>
                    {menuItems.map((item, index) => (
                        <StyledNavLink
                            exact={item.name === 'Home'}
                            to={item.link}
                            activeClassName="active"
                            key={item.name}
                            onMouseEnter={() => setHoveredItem(item)}
                            onMouseLeave={() => setHoveredItem(null)}
                        >
                            <MenuItem>
                                <Typography variant="h5">{item.name}</Typography>
                            </MenuItem>
                        </StyledNavLink>
                    ))}
                </Menu>
            }
        </Navigation>
    );
};

export default NavigationBar;