import React, { useEffect, createContext } from "react";
import { LightTheme } from "./Themes";
import { MuiThemeProvider } from "@material-ui/core/styles";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {

    const theme = "light";

    const toggleTheme = () => {
        // No need to toggle theme as dark mode is disabled
        return;
    };

    // No need to save theme in local storage as dark mode is disabled
    useEffect(() => {}, []);

    return (
        <ThemeContext.Provider
            value={{
                theme,
                toggleTheme,
            }}
        >
            <MuiThemeProvider
                theme={LightTheme}
            >
                {children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
    );
};
