import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, CardContent, Box, Grid } from '@material-ui/core';
import { motion } from 'framer-motion';
import ContactModal from '../ContactModal/ContactModal';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(3),
    },
    Contactcard: {
        height: '200px',
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        background: 'linear-gradient(45deg, #C5DBF0 30%, #C5F0C5 90%)',
        cursor: 'pointer',
        borderRadius: '7px',
    },
    content: {
        overflowY: 'auto',
    },
    heading: {
        marginBottom: theme.spacing(2),
    },
}));

const getInTouchData = [{
    title: 'Get in Touch',
    description: 'Click to open contact form'
}];

const GetInTouch = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className={classes.root}>
            <Typography variant="h6" className={classes.heading}>
                Got Some Questions? Want To Say Hi?
            </Typography>
            <Grid container spacing={2}>
                {getInTouchData.map((project, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <motion.div whileHover={{ scale: 1.05 }} onClick={() => handleClickOpen(project)}>
                            <Card className={classes.Contactcard}>
                                <CardContent className={classes.content}>
                                    <Typography variant="h5">
                                        {project.title}
                                    </Typography>
                                    <Typography variant="body2">
                                        {project.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </motion.div>
                    </Grid>
                ))}
            </Grid>
            <ContactModal open={open} handleClose={handleClose} />
        </Box>
    );
};

export default GetInTouch;
