import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Link, Grid, IconButton } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';

const useStyles = makeStyles((theme) => ({
  footer: {
    background: 'linear-gradient(45deg, #F3F3F3 30%, #D3D3D3 90%)', // Changed to a softer gradient
    padding: theme.spacing(8),  
    marginTop: '6rem', 
    color: '#333', // Changed text color for better contrast against the lighter background
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  socials: {
    marginTop: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(1),  
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.footer}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>
            Abhishek Tiwari
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1">
            <EmailIcon className={classes.icon} />
            abhithe6689@gmail.com
          </Typography>
          <Typography variant="subtitle1">
            <LocationOnIcon className={classes.icon} />
            Boston, MA, USA
          </Typography>
          <Typography variant="subtitle1">
            <PhoneIcon className={classes.icon} />
            +1 (857) 540-3129
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box className={classes.socials}>
            <IconButton component={Link} href="https://www.linkedin.com/in/abhishek-tiwari-1b255a1a9/">
              <LinkedInIcon />
            </IconButton>
            <IconButton component={Link} href="https://github.com/abhi6689-develop">
              <GitHubIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Typography variant="body2" align="center" style={{ marginTop: '1rem' }}>
        © {new Date().getFullYear()} Abhishek Tiwari
      </Typography>
    </Box>
  );
};

export default Footer;
