import React from 'react';
import { Typography, Modal, Box, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    maxHeight: '80%',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const MilestoneModal = ({ milestone, open, handleClose }) => {
  const classes = useStyles();
  if (!milestone) {
    return null;
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.paper}>
        <img src={milestone.logo} alt={milestone.companyName} style={{ width: '100px', height: '50px', marginBottom: '20px' }} />
        <Typography variant="h5">{milestone.companyName}</Typography>
        <Typography variant="subtitle1">{milestone.role}</Typography>
        <Typography variant="subtitle1">{milestone.year}</Typography>
        <Link href={milestone.website} target="_blank" style={{ color: '#00bfbf' }}>{milestone.website}</Link>
        <Typography variant="body1">
          <ul>
            {milestone.description.map((point, index) => (
              <Typography variant="body1" key={index}>
                <li>{point}</li>
              </Typography>
            ))}
          </ul>
        </Typography>
        {milestone.type === 'work' ? (milestone.currentWork ? 'Currently working here' : 'Not working here currently') : ''}
      </Box>
    </Modal>
  );
};

export default MilestoneModal;
