import React, { useState } from 'react';
import { Content } from '../components/content/Content';
import { Hidden, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DisplacementSphere from '../components/background/DisplacementSphere';
import { SocialIcons } from '../components/content/SocialIcons';
import NavigationBar from '../components/NavBar/Navbar';
import NavbarContext from '../Contexts/NavbarContext';
import Footer from '../components/footer/Footer';
import CircleImage from '../components/CircleImage/CircleImage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', 
    justifyContent: 'flex-start',
    minHeight: '100vh',
  },
  content: (props) => ({
    width: '100%',
    marginTop: theme.spacing(props.isSmallScreen ? 3 : 10),
  }),
  navbar: {
    width: '100%',
    position: 'fixed',
    zIndex: 100,
  },
  circleImage: {
    marginTop: theme.spacing(13),
    marginBottom: theme.spacing(3),
  },
}));

export const Home = () => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({isSmallScreen});

  return (
    <NavbarContext.Provider value={{ hoveredItem, setHoveredItem }}>
      <div className={classes.root}>
        <DisplacementSphere />
        
        <div className={classes.navbar}>
          <NavigationBar />
        </div>

        <Hidden smUp>  {/* Circle image visible only on xs screens */}
          <div className={classes.circleImage}>
            <CircleImage imgUrl="Assets/dp3.png" imgAlt="My Circular Image" size="300px" />
          </div>
        </Hidden>

        <div className={classes.content}>
          <Content />
        </div>

        <Hidden smDown>
          <SocialIcons />
        </Hidden>
      </div>
      <Footer />
    </NavbarContext.Provider>
  );
};
