import React from "react";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";

const SkillsGrid = () => {
  const skills = [
    { icon: '/Assets/python-logo.png', description: "Expert in Python", category: "Languages" },
    { icon: '/Assets/sql-logo.png', description: "Expert in SQL", category: "Languages" },
    { icon: '/Assets/javascript-logo.png', description: "Expert in JavaScript", category: "Languages" },
    { icon: '/Assets/swift-logo.png', description: "Proficient in Swift", category: "Languages" },
    { icon: '/Assets/java-logo.png', description: "Proficient in Java", category: "Languages" },
    { icon: '/Assets/react-logo.webp', description: "Proficient in React", category: "Frameworks" },
    { icon: '/Assets/ray-logo.png', description: "Proficient in Ray", category: "Frameworks" },
    { icon: '/Assets/nodejs-logo.png', description: "Proficient in Node.js", category: "Frameworks" },
    { icon: '/Assets/aws-logo.png', description: "Proficient in AWS", category: "Tools" },
    { icon: '/Assets/git-logo.png', description: "Proficient in Git", category: "Tools" },
    { icon: '/Assets/docker-logo.png', description: "Proficient in docker", category: "Tools" },
    { icon: '/Assets/jira-logo.png', description: "Proficient in Jira", category: "Tools" },
    { icon: '/Assets/dsa-logo.png', description: "Proficient in Data Structures & Algorithms", category: "Concepts/Skills" },
    { icon: '/Assets/machinelearning-logo.jpeg', description: "Proficient in Machine Learning", category: "Concepts/Skills" },
    { icon: '/Assets/oop-logo.png', description: "Proficient in Object Oriented Design", category: "Concepts/Skills" },
  ];

  const categories = ['Languages', 'Frameworks', 'Tools', 'Concepts/Skills'];

  const cardStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '15vw',
    width: '15vw',
    minHeight: '100px',
    minWidth: '100px',
    maxHeight: '150px',
    maxWidth: '150px',
    margin: '10px',
  };

  const imgStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  };

  return (
    <div style={{ padding: '20px' }}>
      {categories.map((category, categoryIndex) => (
        <Grid container spacing={3} key={categoryIndex}>
          <Grid item xs={12}>
            <Typography variant="h4">{category}</Typography>
            <Grid container justify="flex-start">
              {skills.filter(skill => skill.category === category).map((skill, skillIndex) => (
                <Card variant="outlined" style={cardStyle} key={skillIndex}>
                  <CardContent>
                    <img src={skill.icon} alt={skill.description} style={imgStyle} />
                  </CardContent>
                </Card>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default SkillsGrid;
