import React from "react";
import DisplacementSphere from '../components/background/DisplacementSphere';
import NavigationBar from '../components/NavBar/Navbar';  // Import the NavigationBar
import SkillsGrid from "../components/SkillGrid/SkillGrid";


export const Skills = () => {
    return (
      <>
        <div>
          <DisplacementSphere />
          <NavigationBar />
          <div style={{marginTop: '10px'}}>
            <SkillsGrid />
          </div>
        </div>
      </>
    );
  };
  