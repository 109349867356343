import React from "react";
import DisplacementSphere from '../components/background/DisplacementSphere';
import NavigationBar from '../components/NavBar/Navbar';  // Import the NavigationBar
// import Timeline from "../components/MileStones/MileStones";
import ProfessionalExperience from "../components/ProfessionalExperience/ProfessionalExperience";
// import AboutMe from "../components/AboutMe/AboutMe";
import MobileExperience from "../components/MobileExperience/MobileExperience";
import { useMediaQuery } from 'react-responsive';

export const Experience = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const milestones = [
        {
            year: 'June - Aug 2023',
            type: 'work',
            companyName: 'Adaptive Investment Solutions',
            role: 'Software Development Engineer Intern',
            description: [
                'Worked on the Back-testing logic, which shows how a portfolio would have performed over time with the company’s ‘Adaptive Shield’ product applied to it and without it.',
                'Worked on risk calculation tools, which use implied volatility to calculate risk associated with a portfolio.'
            ],
            website: 'https://www.adaptive-investments.com',
            logo: '/adaptive-name.png',
            currentWork: true
        },
        {
            year: '2020-2022',
            type: 'work',
            companyName: 'Adaptive Investment Solutions',
            role: 'Software Development Engineer (Part-Time)',
            description: [
                'Designed and implemented back-end hedging logic and REST APIs, which enable users to generate a protection quote for their portfolios and buy them.',
                'Developed a payment processing system using Modern Treasury.',
                'Built and maintained Infrastructure as Code (IaC) on AWS utilising services like AWS CloudFormation, CodePipeline, EC2, Lambda, CodeBuild, DeviceFarm, RDS, BeanStalk, S3, and CloudFront among others.',
                'Implemented AES-256-GCM encryption for secure storage and transmission of sensitive user data.'
            ],
            website: 'https://www.adaptive-investments.com',
            logo: '/adaptive-name.png',
            currentWork: true
        },
        {
            year: 'April-June 2020',
            type: 'work',
            companyName: 'Infoware India',
            role: 'iOS Developer Intern',
            description: [
                'Worked closely with clients to develop custom iOS applications based on their requirements.'
            ],
            website: 'https://www.infowareindia.com',
            logo: 'https://static.wixstatic.com/media/625024_ed057fc70b904d49a449544cb3f104ac~mv2.png/v1/fill/w_150,h_120,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/cropped-Infoware-Logo-.png',
            currentWork: false
        },
        // add more milestones...
    ];

    return (
        <>
            <div>
                <DisplacementSphere />
                <NavigationBar />
                {isMobile ? <MobileExperience milestones={milestones} heading={"Education"} /> : <ProfessionalExperience milestones={milestones} />}
            </div>
        </>
    );

};