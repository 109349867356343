import React from "react";
import DisplacementSphere from '../components/background/DisplacementSphere';
import NavigationBar from '../components/NavBar/Navbar';  // Import the NavigationBar
import ProfessionalExperience from "../components/ProfessionalExperience/ProfessionalExperience";
import { useMediaQuery } from 'react-responsive';
import MobileExperience from "../components/MobileExperience/MobileExperience";
// import AboutMe from "../components/AboutMe/AboutMe";

export const Education = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const milestones = [
        {
            year: '2024 (Expected)',
            type: 'graduation',
            companyName: 'Boston University Graduate School Of Arts & Sciences',
            role: 'MS in Computer Science',
            description: [
                'GPA: 3.77/4.00',
                'Teaching Assistant - DS121: Introduction to Data Science II (Spring 23)'
            ],
            website: 'https://www.bu.edu',
            logo: '/bu_logo.png',
            currentWork: true
        },
        {
            year: '2019-2022',
            type: 'university',
            companyName: 'SP Jain School of Global Management',
            role: 'Bachelor of Data Science',
            description: [
                'GPA: 3.83/4.00',
                'Undergraduate Teaching Assistant - DSC 101: Intro to Databases (Sep 2021 - May 2022)',
                'Achievements: Part of the Dean’s list'
            ],
            website: 'https://www.spjain.org',
            logo: 'https://www.spjain.org/hubfs/home2017/logo.png',
            currentWork: false
        },
        {
            year: '2019',
            type: 'graduation',
            companyName: 'Sri Sri Academy',
            role: 'High School',
            description: [
                'Completed my high school in 2019 from Sri Sri Academy, Kolkata, India.'
            ],
            website: 'https://srisriacademy.in',
            logo: 'https://www.ssa.org.in/wp-content/themes/school/images/logo1.jpg',
            currentWork: false
        },
        // Add more milestones here...
    ];


    return (
        <>
            <div>
                <DisplacementSphere />
                <NavigationBar />
                {isMobile ? <MobileExperience milestones={milestones} heading={"Education"} /> : <ProfessionalExperience milestones={milestones} />}
            </div>
        </>
    );

};