import React from "react";
import DisplacementSphere from '../components/background/DisplacementSphere';
import NavigationBar from '../components/NavBar/Navbar';
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    message: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
    },
}));

export const PageNotFound = () => {
    const classes = useStyles();

    return (
        <>
        <div>
            <DisplacementSphere />
            <NavigationBar/>
            <div className={classes.message}>
                <Typography variant="h2">Uh oh! Page Not Found</Typography>
            </div>
        </div>
        </>
    );
};
