import React from 'react';
import PropTypes from 'prop-types';

const CircleImage = ({ imgUrl, imgAlt, size }) => {
  const style = {
    width: size,
    height: size,
    borderRadius: '50%',
    backgroundImage: `url(${imgUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <div role="img" aria-label={imgAlt} style={style} />
  );
};

CircleImage.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  imgAlt: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

CircleImage.defaultProps = {
  imgAlt: 'Circular Image',
  size: '100px',
};

export default CircleImage;
