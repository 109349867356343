import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Typography, Link } from "@material-ui/core";


const containerVariants = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.5
        }
    }
};

const childVariants = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
    hover: { scale: 1.05 },
};

const descriptionVariants = {
    hidden: { opacity: 0, x: 50 },
    show: { opacity: 1, x: 0 },
};

// ... rest of the code

const ProfessionalExperience = ({milestones}) => {
    const [activeMilestone, setActiveMilestone] = useState(milestones[0]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', width: '100%', position: 'fixed', top: 90, left: 0 }}>
            <div style={{ display: 'flex', height: '80vh', width: '80%', margin: '0 auto', alignItems: 'center' }}>
                <div style={{ overflowY: 'auto', marginRight: '2em', width: '50%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                    <motion.ul
                        variants={containerVariants}
                        initial="hidden"
                        animate="show"
                        style={{ listStyle: 'none', padding: 0, alignSelf: 'center' }}
                    >
                        {milestones.map((milestone, index) => (
                            <Milestone key={index} {...milestone} setActiveMilestone={setActiveMilestone} />
                        ))}
                    </motion.ul>
                </div>
                <motion.div
                    variants={descriptionVariants}
                    initial="hidden"
                    animate={activeMilestone ? 'show' : 'hidden'}
                    style={{
                        backgroundColor: '#f9f9f9',
                        padding: '1em',
                        borderRadius: '8px',
                        width: '50%',
                        height: '80%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        overflow: 'auto'
                    }}
                >
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <div>
                            <Typography variant="h5">{activeMilestone?.companyName}</Typography>
                            <Typography variant="subtitle1">{activeMilestone?.role}</Typography>
                            <Typography variant="subtitle1">{activeMilestone?.year}</Typography>
                            <Link href={activeMilestone?.website} target="_blank" style={{ color: '#00bfbf' }}>{activeMilestone?.website}</Link>
                            <Typography variant="body1">
                                <ul>
                                    {activeMilestone?.description.map((point, index) => (
                                        <Typography variant="body1" key={index}>
                                            <li>{point}</li>
                                        </Typography>
                                    ))}
                                </ul></Typography>
                                {activeMilestone?.type === 'work' ? (activeMilestone?.currentWork ? 'Currently working here' : 'Not working here currently') : ''}
                        </div>
                        <img src={activeMilestone?.logo} alt={activeMilestone?.companyName} style={{ width: '160px', height: '50px', alignSelf: 'flex-start' }} />
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

// ... rest of the code


// ... rest of the code


const Milestone = ({ year, type, companyName, role, setActiveMilestone, ...milestoneData }) => {
    const [hover, setHover] = useState(false);

    return (
        <motion.li
            variants={childVariants}
            whileHover="hover"
            onHoverStart={() => { setActiveMilestone({ ...milestoneData, companyName, role, year }); setHover(true); }}
            onHoverEnd={() => setHover(false)}
            style={{ margin: '1em 0', cursor: 'pointer', display: 'flex', alignItems: 'center', padding: '0 1em', color: hover ? '#00bfbf' : 'inherit' }}
        >
            {type === 'work' && <WorkSVG />}
            {type === 'university' && <UniversitySVG />}
            {type === 'graduation' && <GraduationSVG />}

            <div style={{ marginLeft: '1em' }}>
                <Typography variant="h5">{companyName}</Typography>
                <Typography variant="subtitle1">{role}</Typography>
                <Typography variant="subtitle1">{year}</Typography>
            </div>
        </motion.li>
    )
};

// Replace these with actual SVGs
const WorkSVG = () => <span role="img" aria-label="work">👔</span>;
const UniversitySVG = () => <span role="img" aria-label="university">🎓</span>;
const GraduationSVG = () => <span role="img" aria-label="graduation">🖊️</span>;

export default ProfessionalExperience;