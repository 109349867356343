import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, CardContent, Box, Grid } from '@material-ui/core';
import { motion } from 'framer-motion';
import MilestoneModal from '../MilestoneModal/MilestoneModal';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  card: {
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    background: 'linear-gradient(45deg, #f0c5f0 30%, #f0f0c5 90%)',
    cursor: 'pointer',
    borderRadius: '7px',
  },
  content: {
    overflowY: 'auto',
  },
  heading: {
    marginBottom: theme.spacing(2),
    textAlign: 'center', // Center the heading
  },
}));

const MobileExperience = ({ milestones, heading }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedMilestone, setSelectedMilestone] = useState(null);

  const handleClickOpen = (milestone) => {
    setSelectedMilestone(milestone);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className={classes.root}>
      {/* <Typography variant="h4" className={classes.heading}>
        {heading}
      </Typography> */}
      <Grid container>
        {milestones.map((milestone, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <motion.div whileHover={{ scale: 1.05 }} onClick={() => handleClickOpen(milestone)}>
              <Card className={classes.card}>
                <CardContent className={classes.content}>
                  <Typography variant="h5">
                    {milestone.companyName}
                  </Typography>
                  <Typography variant="subtitle1">
                    {milestone.role}
                  </Typography>
                  <Typography variant="body2">
                    {milestone.year}
                  </Typography>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
      <MilestoneModal milestone={selectedMilestone} open={open} handleClose={handleClose} />
    </Box>
  );
};

export default MobileExperience;
