import React, { lazy, Suspense } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HelmetMeta } from "./HelmetMeta";
import { ThemeProvider } from "../components/theme/ThemeProvider";
import { CssBaseline } from "@material-ui/core";
import { logCredits } from "../utils/logCredits";

import { Home } from "../pages/Home";
import { About } from "../pages/About";
import { PageNotFound } from "../pages/PageNotFound";
import {Education} from "../pages/Education";
import { Experience } from "../pages/Experience";
import { Skills } from "../pages/Skills";

const Resume = lazy(() => import("../pages/Resume"));
// const About = lazy(() => import("../pages/About")); 

export const App = () => {
    logCredits();

    return (
        <ThemeProvider>
            <CssBaseline />
            <Router>
                <HelmetMeta />
                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        <Route path="/home" exact component={Home} />
                        <Route path="/" exact component={Home} />
                        <Route path="/resume" component={Resume} />
                        <Route path="/about" exact component={About} />
                        <Route path="/education" exact component={Education} />
                        <Route path="/experience" exact component={Experience} />
                        <Route path="/skills" exact component={Skills} />
                        <Route path="*" exact component={PageNotFound} />
                    </Switch>
                </Suspense>
            </Router>
        </ThemeProvider>
    );
};
