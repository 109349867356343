import React, { useContext } from "react";
import { Typography, Container, Box, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Resume from "../../settings/resume.json";
import { FirstName } from "../../utils/getName";
import CircleComponent from '../Circle/CircleComponent'
import NavbarContext from "../../Contexts/NavbarContext";
import { motion } from "framer-motion";
import Projects from "../ProjectCards/ProjectCards";
import GetInTouch from "../ContactCard/ContactCard";

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: "auto",
        marginBottom: "auto",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
    },
    card: {
        background: 'linear-gradient(45deg, #FFFFFF 30%, #33D2D2 90%)',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: '10px',
        "@media (max-width: 768px)": {
            flexDirection: 'column',
            justifyContent: 'center',
        },
    },
    circle: {
        marginRight: '20px',
        "@media (max-width: 768px)": {
            display: 'none', // Hide the circle on narrow screens
        },
    },
}));

export const Content = () => {
    const classes = useStyles();
    const { hoveredItem } = useContext(NavbarContext)

    return (
        <Container component="main" className={`${classes.main}`}>
            <motion.div
                className={classes.card}
                whileHover={{ scale: 1.00, rotateY: 10 }}
            >
                <CardContent>
                    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" width="100%">
                        <Box className={classes.circle}>
                            <CircleComponent hoveredItem={hoveredItem} />
                        </Box>
                        <Box>
                            <Typography variant="h2" component="h1" gutterBottom>
                                {Resume.basics.x_title} {FirstName}
                            </Typography>
                            <Typography variant="body1" component="p" gutterBottom>
                                I'm a Computer Science graduate student at Boston University. I'm passionate about building software that makes a difference. I'm currently looking for full-time opportunities in Software Engineering, Data Science, and Machine Learning. I'm also open to internships and research opportunities. Feel free to reach out to me if you think I'd be a good fit for your team.
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </motion.div>
            <div>
                <Projects />
                <GetInTouch />
            </div>
        </Container>
    );
};
