import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Dialog, DialogTitle, DialogContent, Box, Link } from '@material-ui/core';
import { AnimatePresence, motion } from 'framer-motion';
import GitHubIcon from '@material-ui/icons/GitHub';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        padding: theme.spacing(2),
        backgroundColor: '#FE6B8B',
    },
    dialogContent: {
        padding: theme.spacing(2),
    },
    projectTitle: {
        marginBottom: theme.spacing(2),
    },
    projectTech: {
        marginBottom: theme.spacing(2),
    },
    projectFunctionality: {
        marginBottom: theme.spacing(2),
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.primary,
    },
    icon: {
        marginRight: theme.spacing(1),
    },
}));

const ProjectModal = ({ project, open, handleClose }) => {
    const classes = useStyles();
    const MotionDialog = motion(Dialog);


    return (
        <AnimatePresence>
            {open && (
                <MotionDialog
                    open={open}
                    onClose={handleClose}
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 50 }}
                    transition={{ duration: 0.3 }}
                >
                    <DialogTitle className={classes.dialogTitle}>Project Details</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Typography variant="h5" className={classes.projectTitle}>
                            {project.title}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.projectTech}>
                            Technologies Used: {project.technology}
                        </Typography>
                        {/* <Typography variant="body2" className={classes.projectFunctionality}>
                            Functionality: {project.functionality}
                        </Typography> */}
                        <Typography variant="body1">
                            Detailed Description:
                        </Typography>
                        <Typography variant="body2">
                            {project.detailed_description}
                        </Typography>
                        <Box mt={3}>
                            <Link href={project.githubLink} target="_blank" className={classes.link}>
                                <GitHubIcon className={classes.icon} />
                                View on GitHub
                            </Link>
                        </Box>
                    </DialogContent>
                </MotionDialog>
            )}
        </AnimatePresence>
    );
};

export default ProjectModal;
