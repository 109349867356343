// ContactModal.jsx
import React, { useState } from 'react';
import { Dialog, TextField, Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

const ContactModal = ({ open, handleClose }) => {
  const [contactData, setContactData] = useState({ name: '', email: '', message: '' });

  const handleChange = (event) => {
    setContactData({ ...contactData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(contactData);  // log the form data
    handleClose();  // close the modal
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Get in touch</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            name="message"
            label="Message"
            type="text"
            fullWidth
            multiline
            rows={4}
            onChange={handleChange}
            required
          />
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Send
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ContactModal;
