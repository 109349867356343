import React from "react";
import DisplacementSphere from '../components/background/DisplacementSphere';
import NavigationBar from '../components/NavBar/Navbar';  // Import the NavigationBar
import Timeline from "../components/MileStones/MileStones";
import AboutMe from "../components/AboutMe/AboutMe";

export const About = () => {
    return (
        <>
        <div>
            <DisplacementSphere />
            <NavigationBar/>
            {/* <Timeline/> */}
            <AboutMe/>
        </div>
        </>
    );

};